import React from "react";
import "./Services.css";
import MySwiper from "../../Components/Swiper/Swiper";
import { useLocation } from "react-router-dom";

const Services = () => {
  const location = useLocation();
  const serviceData = location.state || {};

  return (
    <>
      {/* Hero Section */}
      <div 
        style={{ backgroundImage: `url(${serviceData.image || ''})` }} 
        className="services-hero-section-container"
      >
        <div className="services-hero-section container">
          <div className="service-title">{serviceData.name || 'Service Name'}</div>
          <div className="three-words">{serviceData.threeWords || 'Three Words Here'}</div>
        </div>
      </div>

      {/* Details and Swiper Section */}
      <div className="services-section-two">
        <div className="services-col1">
          {serviceData.details && serviceData.details.length > 0 ? (
            serviceData.details.map((detail: string, index: number) => (
              <div key={index} className="detail-item">
                <strong className="detail-title">{detail.split(":")[0]}</strong>
                <span className="detail-content">{detail.split(":")[1]}</span>
              </div>
            ))
          ) : (
            <div>No details available</div>
          )}
        </div>
        <div className="services-col2">
          {serviceData.images && serviceData.images.length > 0 ? (
            <MySwiper images={serviceData.images} />
          ) : (
            <div>No images available</div>
          )}
          <div className="services-content-title">
            <span className="ideal-for">Ideal for:</span>
            <ul className="ideal-for-list">
              {serviceData.idealFor ? 
                serviceData.idealFor.split(',').map((item: string, index: number) => (
                  <li key={index} className="ideal-for-item">{item.trim()}</li>
                )) 
                : 'Not specified'}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Services;
