import React from 'react';
import { Swiper as SwiperReact, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Navigation } from 'swiper/modules';
import './Swiper.css';

interface MySwiperProps {
  images: string[];
}

const MySwiper: React.FC<MySwiperProps> = ({ images }) => {
  return (
    <div className=''>
     

      <SwiperReact
        pagination={{
          type: 'bullets',
        }}
        navigation={true}
        modules={[Pagination, Navigation]}
        className="mySwiper mt-8"
      >
        {images.map((image, index) => (
          <SwiperSlide key={index} className='swiper-slide'> 
            <img src={image} alt={`Slide ${index + 1}`} loading='lazy'/>
          </SwiperSlide>
        ))}
      </SwiperReact>
      
    </div>
  );
};

export default MySwiper;
