import React from 'react';
import Tabs from '../../Components/Tabs/Tabs';
import { tabData } from '../../Components/TabsContent/TabsContent';

const ServicesPage = () => {
  return (
    <section id="services-section" className="services-section">
      <div className="meet-team">Services</div>
      <Tabs tabs={tabData} />
    </section>
  );
};

export default ServicesPage;
