import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./TabsContent.css";

import theater4 from "../../assets/services/theater&cinema/theater4.png";
import theater6 from "../../assets/services/theater&cinema/theatercinemaview.jpg";
import theater3 from "../../assets/services/theater&cinema/theaternew1.jpg";
import theater5 from "../../assets/services/theater&cinema/theaternew22.jpg";
import theater7 from "../../assets/services/theater&cinema/theaternew8.jpg";
import theater2 from "../../assets/services/theater&cinema/theaternew9.jpg";
import theater123 from "../../assets/services/theater&cinema/theaternewnew12.jpg";
import theaterWide from "../../assets/services/theater&cinema/theaterwideimage.jpg";

import theaterSlider1 from "../../assets/services/theater&cinema/slider/theater1.jpg";
import theaterSlider2 from "../../assets/services/theater&cinema/slider/theater2.png";
import theaterSlider3 from "../../assets/services/theater&cinema/slider/theater3.jpg";
import theaterSlider4 from "../../assets/services/theater&cinema/slider/theater4.jpg";
import theaterSlider5 from "../../assets/services/theater&cinema/slider/theater5.jpg";
import theaterSlider6 from "../../assets/services/theater&cinema/slider/theater6.jpg";
import theaterSlider7 from "../../assets/services/theater&cinema/slider/theater7.png";

import hall1 from "../../assets/services/hall/hall1.png";
import multiLanding from "../../assets/services/hall/multilanding11.jpg";
import hall2 from "../../assets/services/hall/multinew10.jpg";
import hall4 from "../../assets/services/hall/multinew1111.png";
import multi1 from "../../assets/services/hall/multinew3.jpg";

import hallSlider9 from "../../assets/services/hall/slider/hallslider10.jpg";
import hallSlider3 from "../../assets/services/hall/slider/hallslider3.jpg";
import hallSlider4 from "../../assets/services/hall/slider/hallslider4.jpg";
import hallSlider5 from "../../assets/services/hall/slider/hallslider5.jpg";
import hallSlider6 from "../../assets/services/hall/slider/hallslider6.jpg";
import hallSlider7 from "../../assets/services/hall/slider/hallslider7.jpg";
import hallSlider8 from "../../assets/services/hall/slider/hallslider9.jpg";

import meetingLanding from "../../assets/services/meeting/meetinglanding.jpg";
import meeting4 from "../../assets/services/meeting/meetingnew2.jpg";
import meeting2 from "../../assets/services/meeting/meetingRoom2.jpg";
import meeting1 from "../../assets/services/meeting/meetingRoom3.jpg";

import meetingSlider1 from "../../assets/services/meeting/slider/meetingslider1.jpg";
import meetingSlider2 from "../../assets/services/meeting/slider/meetingslider2.jpg";
import meetingSlider3 from "../../assets/services/meeting/slider/meetingslider3.jpg";
import meetingSlider4 from "../../assets/services/meeting/slider/meetingslider4.jpg";

import coffee3 from "../../assets/services/coffee/coffee3.png";
import coffee4 from "../../assets/services/coffee/coffee4.png";
import coffee6 from "../../assets/services/coffee/coffee6.png";
import coffeeLanding from "../../assets/services/coffee/coffeewide.jpg";
import coffee2 from "../../assets/services/coffee/coffeenew44.jpg";
import coffee5 from "../../assets/services/coffee/coffeenew66.jpg";
import coffee1 from "../../assets/services/coffee/coffeenewnew111.jpg";
import coffee7 from "../../assets/services/coffee/coffenew5.jpg";

import coffeeSlider1 from "../../assets/services/coffee/slider/coffeeslider1.jpg";
import coffeeSlider2 from "../../assets/services/coffee/slider/coffeeslider2.jpg";
import coffeeSlider3 from "../../assets/services/coffee/slider/coffeeslider3.jpg";
import coffeeSlider4 from "../../assets/services/coffee/slider/coffeeslider4.jpg";
import coffeeSlider5 from "../../assets/services/coffee/slider/coffeeslider5.jpg";

import coworking6 from "../../assets/services/coworking/coworking121212.jpg";
import coworking4 from "../../assets/services/coworking/coworking15.png";
import coworking5 from "../../assets/services/coworking/coworking17.jpg";
import coworkingLanding from "../../assets/services/coworking/coworkinglanding.jpg";
import coworking3 from "../../assets/services/coworking/coworkoing 1.jpg";

import coworkingSlider1 from "../../assets/services/coworking/slider/coworkingslider1.jpg";
import coworkingSlider2 from "../../assets/services/coworking/slider/coworkingslider2.jpg";
import coworkingSlider3 from "../../assets/services/coworking/slider/coworkingslider3.jpg";
import coworkingSlider4 from "../../assets/services/coworking/slider/coworkingslider4.jpg";
import coworkingSlider5 from "../../assets/services/coworking/slider/coworkingslider5.jpg";

import mez from "../../assets/services/coffee/mezanine.jpg";

const roomsData = [
  {
    name: "Cinema & Theatre",
    threeWords: "Curiosity, Creativity, Compassion",
    details: [
      "Stage Dimensions: 6.9 times 14.4",
      "Backstage with toilets",
      "Power Supply Type: Generator",
      "Audio: 6 speakers (15 inch), 3 left and 3 right (connected to the amps), 2 wireless microphones",
      "Air Conditioning",
      "Screen: (7 * 12)",
      "Cinema Projector: Christie",
      "Lighting: 8 RGBW LED PAR, 5 pcs 1000 watt (above the audience facing the stage), 4 sides facing the stage (2 left, 2 right)",
      "Dimmer: 6 channel + 4 channel",
      "Console: 24 channel",
      "Capacity: 447 seats",
    ],
    image: theaterWide,
    idealFor:
      "Film Screenings, Plays, Musical Performances, Workshops, Seminars, Graduations",
    tabImages: [
      theater123,
      theater2,
      theater3,
      theater4,
      theater5,
      theater6,
      theater7,
    ],
    images: [
      theaterSlider1,
      theaterSlider2,
      theaterSlider3,
      theaterSlider4,
      theaterSlider5,
      theaterSlider6,
      theaterSlider7,
    ],
  },
  {
    name: "Multi-Disciplinary Room",
    threeWords: "Imagine, Inspire, Innovate",
    details: [
      "Customizable space for teams' meeting, training & events",
      "Audiovisual Equipment: TV + HDMI + Speakers + Two Microphones",
      "White Board with flip chart",
      "Fast & free Internet",
      "Air Conditioning",
      "Catering services",
      "Capacity: Welcomes up to 50 people",
    ],
    image: multiLanding,
    idealFor: "Workshops, Talks, Meetings, Seminars, Exhibitions",
    tabImages: [hall1, hall2, multi1, hall4],
    images: [
      hallSlider8,
      hallSlider9,
      hallSlider4,
      hallSlider5,
      hallSlider6,
      hallSlider3,  
      hallSlider7,
    ],
  },
  {
    name: "Meeting Room",
    threeWords: "Connectedness, Construction, Clarity",
    details: [
      "Fixed meeting table",
      "Audiovisual Equipment: TV + HDMI",
      "White Board with flip chart",
      "Fast & free Internet",
      "Air Conditioning",
      "Catering services",
      "Capacity: Welcomes up to 15 people",
    ],
    image: meetingLanding,
    idealFor:
      "Interviews, Freelancers, Tutors, Zoom calls, group work/meetings, small training",
    tabImages: [meeting1, meeting2, meeting4],
    images: [meetingSlider1, meetingSlider2, meetingSlider3, meetingSlider4],
  },
  {
    name: "Coworking Space",
    threeWords: "Engage, Elevate, Excel",
    details: [
      "Free & unlimited access to the coworking area during business hours",
      "Calm & Convenient Workspace",
      "Fast & free Internet",
      "Air Conditioning",
    ],
    image: coworkingLanding,
    idealFor: "Studying, Working, Reading a Book",
    tabImages: [
      coworking4,
      coworkingLanding,
      coworking3,
      coworking5,
      coworking6,
    ],
    images: [
      coworkingSlider1,
      coworkingSlider3,
      coworkingSlider4,
      coworkingSlider5,
    ],
  },
  {
    name: "Coffee Shop",
    threeWords: "Relax, Recharge, Reflect",
    details: [
      "Variety of Hot & Cold Drinks, Yummy Desserts & a selection of fresh sandwiches",
      "Homey and Cosy",
      "Flexible Workspace",
      "Fast & free Internet",
      "Air Conditioning",
    ],
    image: coffeeLanding,
    idealFor:
      "Chilling, Studying, Working on laptops, Catching up with friends, Sipping coffee, Have some me time, Poetry Nights, Playing board games",
    tabImages: [coffee1, coffee2, coffee3, coffee4, coffee5, coffee6, coffee7],
    images: [
      coffee1,
      coffeeSlider1,
      coffee2,
      coffeeSlider2,
      coffee7,
      coffeeSlider3,
      coworkingSlider2,
      coffeeSlider4,
      coffeeSlider5,
    ],
  },
  {
    name: "Mezzanine Studio",
    threeWords: "Relax, Recharge, Reflect",
    details: [
      "Variety of Hot & Cold Drinks, Yummy Desserts & a selection of fresh sandwiches",
      "Homey and Cosy",
      "Flexible Workspace",
      "Fast & free Internet",
      "Air Conditioning",
    ],
    idealFor:
      "Chilling, Studying, Working on laptops, Catching up with friends, Sipping coffee, Have some me time, Poetry Nights, playing board games",
    tabImages: [mez],
    images: [mez],
  },
];

const TabContentItem: React.FC<{ index: number }> = ({ index }) => {
  const navigate = useNavigate();
  const [filteredImages, setFilteredImages] = useState<string[]>([]);
  const tabImages = roomsData[index]?.tabImages || [];

  const handleClick = () => {
    if (roomsData[index].name === "Mezzanine Studio") {
      return;
    }
    navigate("/services", { state: roomsData[index] });
  };

  useEffect(() => {
    const updateImages = () => {
      // Filter to show only the first 6 images under 992px
      if (tabImages.length > 6 && window.innerWidth < 992) {
        const filtered = tabImages.filter((_, index) => index !== 3).slice(0, 6);
        setFilteredImages(filtered);
      } else {
        setFilteredImages(tabImages); // Show all images above 992px
      }
    };

    updateImages(); // Initial check
    window.addEventListener("resize", updateImages); // Update on resize
    return () => window.removeEventListener("resize", updateImages);
  }, [tabImages]);

  return (
    <div className="image-grid" onClick={handleClick}>
      {filteredImages.length === 1
        ? filteredImages.map((imageSrc, i) => (
            <img
              key={i}
              src={imageSrc}
              alt={`Image ${i + 1}`}
              className={`grid-image-mezzanine image1${i}`}
            />
          ))
        : filteredImages.length === 7
        ? filteredImages.map((imageSrc, i) => (
            <img
              key={i}
              src={imageSrc}
              alt={`Image ${i + 1}`}
              className={`grid-image image${i + 1}`}
            />
          ))
        : filteredImages.length === 5
        ? filteredImages.map((imageSrc, i) => (
            <img
              key={i}
              src={imageSrc}
              alt={`Image ${i + 1}`}
              className={`grid-image image5${i + 1}`}
            />
          ))
        : filteredImages.length === 4
        ? filteredImages.map((imageSrc, i) => (
            <img
              key={i}
              src={imageSrc}
              alt={`Image ${i + 1}`}
              className={`grid-image image4${i + 1}`}
            />
          ))
        : filteredImages.length === 3
        ? filteredImages.map((imageSrc, i) => (
            <img
              key={i}
              src={imageSrc}
              alt={`Image ${i + 1}`}
              className={`grid-image image3${i + 1}`}
            />
          ))
        : filteredImages.length === 6 ? filteredImages.map((imageSrc, i) => (
          <img
            key={i}
            src={imageSrc}
            alt={`Image ${i + 1}`}
            className={`grid-image image6${i + 1}`}
          />
        )) :filteredImages.map((imageSrc, i) => (
            <img
              key={i}
              src={imageSrc}
              alt={`Image ${i + 1}`}
              className={`grid-image image2${i + 1}`}
            />
          ))}
    </div>
  );
};

export const tabData = [
  { title: "Cinema & Theatre", content: <TabContentItem index={0} /> },
  { title: "Multi-Disciplinary Room", content: <TabContentItem index={1} /> },
  { title: "Meeting Room", content: <TabContentItem index={2} /> },
  { title: "Coworking Space", content: <TabContentItem index={3} /> },
  { title: "Coffee Shop", content: <TabContentItem index={4} /> },
  { title: "Mezzanine Studio", content: <TabContentItem index={5} /> },
];
