import React from "react";
import "./AboutUs.css";

const AboutUs: React.FC = () => {
  return (
    <div className="about-section">
      <div className="about-left">
        <h2 className="about-us-word">ABOUT US</h2>
        <h2 className="about-us-word-mobile">ABOUT US</h2>
      </div>
      <div className="about-mid">
      <p>
      Ishbilia Theatre and Art Hub is a grassroots independent cultural
      space located in the heart of Sidon South Lebanon.
    </p>
    <p>
      Originally founded as a cinema house in 1980 by Adnan Zibaoui.
      Ishbilia was the go-to place for cinema lovers and families in Saida.
    </p>
    <p>
      In 2008, Ishbilia closed its doors, yet in 2018, Nahla and Hiba Zibaoui
      opened it again with a modern and youthful spirit, aiming to decentralize
      the culture scene in Lebanon.
    </p>
    <p>
      We are devoted to activate the culture scene in Saida and South Lebanon
      through conducting different cultural, artistic, and educational activities.
    </p>
    <p>
      Ishbilia is an experimental learning hub for the Sidon community,
      artists, students, and activists - a place to ask questions, exchange
      ideas, dream, and grapple with our contemporary situation.
    </p>
        <p style={{ fontWeight: "bold" }}>
          Ishbilia for Arts Association is a non-profit organization based in
          Saida, registered at the Lebanese Ministry of Interior and
          Municipalities under 2215
        </p>
      </div>
      <div className="about-mission-right"></div>
    </div>
  );
};

export default AboutUs;
