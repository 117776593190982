import React from "react";
import Mission from "../../Components/AboutUs/Mission";
import AboutUs from "../../Components/AboutUs/AboutUs";
import Vision from "../../Components/AboutUs/Vision";

const About = () => {
  return (
    <div className="about-page-container">
      <Mission />
      <AboutUs />
      <Vision />
    </div>
  )
};

export default About;
