import React from "react";
import { useNavigate } from "react-router-dom";
import afacLogo from "../../assets/imgs/home/afac-logofull.png";
import banner from "../../assets/imgs/home/ishbilia new 1.jpg";
import ContactUs from "../../Components/ContactUs/ContactUs";
import MeetTeamComp from "../../Components/MeetTeam/MeetTeamComp";
import ServicesPage from "../Services/ServicesPage";
import "./Home.css";

const Home = () => {

  const navigate = useNavigate();

  return (
    <>
      <section className="hero-section container">
        <div className="hero-section-paragraphs-container">
          <div className="hero-section-big-title">
            A place of possibilities, a place for you to explore and get
            inspired.
          </div>
          <div className="hero-section-small-title">
            We have been here since 1980. We exist to empower our community and
            local artists by providing mediums and different
            artistic expressions, which support their innovation and
            creativity. 
          </div>
        </div>
        <div className="tb-hero-img tb-style1">
          <div className="tb-hero-img-box">
            <img src={banner} className="hero-image" alt="banner" />
          </div>
          <div className="tb-hero-img-box-circle"></div>
          <div className="tb-hero-img-box-square"></div>
        </div>
      </section>
      {/* <section className="section2">
        <div className="our-journey container">
          <div className="our-journey-title">Our Journey</div>
          <div className="our-journey-timeline">
            <div className="oj-event">
              <FontAwesomeIcon icon={faBuilding} className="oj-icon" />
              <div className="oj-title">Cinema House</div>
              <div className="oj-content">
                A safe, independent, and dynamic cultural space and work hub in
                Saida
              </div>
              <div className="oj-times">2018 - 2024</div>
            </div>
            <div className="oj-event">
              <FontAwesomeIcon icon={faClapperboard} className="oj-icon" />
              <div className="oj-title">First Screening</div>
              <div className="oj-content">
                A safe, independent, and dynamic cultural space and work hub in
                Saida
              </div>
              <div className="oj-times">1989 - 1990</div>
            </div>
            <div className="oj-event">
              <FontAwesomeIcon icon={faMasksTheater} className="oj-icon" />
              <div className="oj-title">Award Received</div>
              <div className="oj-content">
                A safe, independent, and dynamic cultural space and work hub in
                Saida
              </div>
              <div className="oj-times">1989 - 1990</div>
            </div>
            <div className="oj-event">
              <FontAwesomeIcon icon={faPaintRoller} className="oj-icon" />
              <div className="oj-title">Reopened</div>
              <div className="oj-content">
                A safe, independent, and dynamic cultural space and work hub in
                Saida
              </div>
              <div className="oj-times">March 2, 1980</div>
            </div>
          </div>
          <div className="oj-read-more" onClick={()=> navigate("/about")}>Read more about us here</div>
        </div>
      </section> */}
      {/* <About/> */}
      <section className="who-we-are-section" onClick={() => navigate("/about")}>
        <div className="who-we-are">
          <p className="who-we-are-word">WHO WE ARE ?</p>
          {/* <p className="click-instruction">Click to learn more</p> */}
          <button className="learn-more-button">Learn More</button>
        </div>
      </section>
      <ServicesPage />
      <section className="events-section" onClick={() => navigate("/events")}>
        <div className="events-upcoming">Upcoming Events</div>
        <div className="events-whats">What's coming to Ishbilia?</div>
      </section>
      <MeetTeamComp/>
      <hr />
      <ContactUs />
      <div className="afac-section">
      <img src={afacLogo} className="afac-logo"/>
      <p className="supported-statement">This website is supported by AFAC Arab Fund for Arts and Culture.</p>
      </div>
      <hr />
    </>
  );
};

export default Home;
