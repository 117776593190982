import React, { useState } from 'react'
import { meetTeam } from '../../utilities'
import MeetTeamModal from '../MeetTeamModal/MeetTeamModal2'

const MeetTeamComp = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMember, setSelectedMember] = useState({
      name: "",
      role: "",
      title: "",
      content: "",
      image: "",
    });
    const openModal = (member: any) => {
      console.log("Selected Member Data:", member);
      setSelectedMember(member);
      setIsModalOpen(true);
    };
    const closeModal = () => setIsModalOpen(false);
  return (
    <section className="team-section">
    <div className="meet-team">Meet the Team</div>
    <div className="team-members-container container">
      {meetTeam.map((member, index) => (
        <div key={index} onClick={() => openModal(member)} className='team-member-card'>
          <div className="member-image"></div>
          <div className="member-name">{member.name}</div>
          <div className="member-role">{member.role}</div>
        </div>
      ))}
    </div>
    <MeetTeamModal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      name={selectedMember.name}
      role={selectedMember.role}
      title={selectedMember.title}
      content={selectedMember.content}
      image={selectedMember.image}
    />
  </section>
  )
}

export default MeetTeamComp