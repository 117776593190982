import React, { useState } from "react";
// @ts-ignore: Unreachable code error
import emailjs, { EmailJSResponseStatus } from "emailjs-com";
import Swal from "sweetalert2";
import "./ContactUs.css"

type FormData = {
  from_name: string;
  from_email: string;
  message: string;
};

const ContactUs: React.FC = () => {
  const [formData, setFormData] = useState<FormData>({
    from_name: "",
    from_email: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    emailjs
      .send(
        "service_vizf8t7",
        "template_exgmk4w",
        formData,
        "WV756Q23R638pQ5b9"
      )
      .then(
        (result: EmailJSResponseStatus) => {
          console.log("Email successfully sent!", result.text);
          Swal.fire({
            title: "Email sent!",
            icon: "success",
          });
          setFormData({
            from_name: "",
            from_email: "",
            message: "",
          });
        },
        (error: { text: string }) => {
          console.error("Error sending email", error.text);
          alert("Failed to send email, please try again later.");
        }
      );
  };

  return (
    <section className="contact-us-section container py-5">
      <div className="text-start mb-5">
        <h2 className="contact-word meet-team">Contact Us</h2>
      </div>
      <div className="row justify-content-between">
        <div className="col-md-5 mb-4">
          <div className="contact-part mb-4">
            <h5 className="contact-title">Address</h5>
            <p className="contact-content">
              Houssam Eddine Hariri Street, Mosbah building, Saida, Lebanon
            </p>
            <a
              href="https://maps.app.goo.gl/bgMAGCBk15VQEYb56"
              className="contact-directions text-decoration-none"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get directions
            </a>
          </div>
          <div className="contact-part mb-4">
            <h5 className="contact-title">Opening Hours</h5>
            <p className="contact-content">Monday - Saturday:</p>
            <p className="contact-content">9 AM - 9 PM</p>
          </div>
          <div className="contact-part mb-4">
            <h5 className="contact-title">Contact us</h5>
            <p className="contact-content">+961 03 190 497 / +961 71 270 090</p>
            <p className="contact-content">info@ishbiliatheatre.com</p>
          </div>
        </div>

        <div className="col-md-6">
          <form onSubmit={sendEmail}>
            <div className="input-part mb-3">
              <label htmlFor="from_name" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                id="from_name"
                name="from_name"
                value={formData.from_name}
                onChange={handleChange}
                placeholder="Enter your name"
                required
              />
            </div>
            <div className="input-part mb-3">
              <label htmlFor="from_email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="from_email"
                name="from_email"
                value={formData.from_email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>
            <div className="mb-2">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
                required
              ></textarea>
            </div>
            <button type="submit" className="submit-btn w-100">
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
