import React from "react";
import img3 from "../../assets/imgs/about/theater3333.jpg";
import "./AboutUs.css";

const Vision: React.FC = () => {
  return (
    <div className="vision-section">
      <div className="about-right">
        <img src={img3} alt="About Ishbilia" />
      </div>
      <div className="vision-content vision-content-left">
        <h2>VISION</h2>
        <p>
          Empowering the growth of a thriving, creative community and making
          arts and knowledge accessible to all.
        </p>
      </div>
      <div className="color-blocks color-blocks-vision">
        <div className="color-block color-purple color-purple-vision"></div>
        <div className="color-block color-purple color-purple-vision"></div>
        <div className="color-block color-light-orange"></div>
        <div className="color-block color-yellow color-yellow-vision"></div>
        <div className="color-block color-red"></div>
        <div className="color-block color-blue"></div>
        <div className="color-block color-blue"></div>
      </div>
    </div>
  );
};

export default Vision;
