import React from "react";
import { Modal, Box, Typography, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import './MeetTeamModal.css'

interface MeetTeamModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  name: string;
  role: string;
  title: string;
  content: string;
  image: string;
}

const MeetTeamModal: React.FC<MeetTeamModalProps> = ({
  isOpen,
  onRequestClose,
  name,
  role,
  title,
  content,
  image,
}) => {
  return (
    <Modal open={isOpen} onClose={onRequestClose}>
      <Box
        className="modal-container"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "8px",
          p: 3,
          maxWidth: "900px",
          width: "90%",
          display: "flex",
          flexDirection: { xs: "column", md: "row" }, // Responsive layout
          gap: 3,
        }}
      >
        {/* Close Button */}
        <IconButton
          onClick={onRequestClose}
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            color: "#707070",
          }}
        >
          <CloseIcon />
        </IconButton>

        {/* Left Column: Image */}
        <Box
          className="model-member-image"
          display="flex"
          flexDirection="column"
          sx={{
            width: { xs: "100%", md: "321px" }, // Full width on small screens
            height: { xs: "auto", md: "350px" }, // Auto height for scaling
            overflow: "hidden",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={image}
            alt={name}
            className="model-member-image-content"
          />
          <Typography
            className="modal-title"
            variant="h5"
            sx={{ fontWeight: "bold", fontSize: { xs: "18px", md: "22px" } }}
          >
            {name}
          </Typography>
          <Typography
            className="modal-role"
            variant="subtitle2"
            sx={{ color: "#707070", fontSize: { xs: "14px", md: "16px" } }}
          >
            {role}
          </Typography>
        </Box>

        {/* Right Column: Text */}
        <Box
          className="modal-text-content"
          sx={{
            textAlign: { xs: "center", md: "left" }, // Center text for small screens
            width: "100%",
          }}
        >
          
          <Typography
            className="modal-section-title"
            variant="subtitle1"
            sx={{ fontWeight: "bold", fontSize: { xs: "16px", md: "18px" } }}
          >
            {title}
          </Typography>
          <Typography
            className="modal-description"
            variant="body1"
            sx={{
              fontSize: { xs: "14px", md: "16px" },
              lineHeight: "1.5",
              whiteSpace: "pre-wrap",
            }}
          >
            {content}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default MeetTeamModal;
