import React from "react";
import "./AboutUs.css";
import img1 from "../../assets/imgs/about/about1.jpg";

const Mission: React.FC = () => {
  return (
    <div className="mission-section">
        {/* <h2 className=" mission-on-mobile">MISSION</h2> */}
      <div className="mission-content">
        <h2 className="mission-big-screen">MISSION</h2>
        <p>
          We want Ishbilia to become the go-to cultural hub in the South of
          Lebanon by providing our community of young curators, artists,
          intellectuals, and students in Saida with dynamic, accessible, and
          supportive high-quality service in a safe inspiring environment.
        </p>
      </div>

      <div className="mission-section-2">
        <div className="color-blocks first-section-mission-blocks">
          <div className="color-block color-red color-block-mission"></div>
          <div className="color-block color-purple color-block-mission"></div>
          <div className="color-block color-yellow color-block-mission"></div>
          <div className="color-block color-green color-block-mission"></div>
          <div className="color-block color-turquoise color-block-mission"></div>
        </div>

        <div className="mission-image">
          <img src={img1} alt="Team at Ishbilia" />
        </div>

        <div className="color-blocks second-color-blocks color-block-mission">
          <div className="color-block color-yellow-two color-block-mission"></div>
          <div className="color-block color-orange color-block-mission"></div>
          <div className="color-block color-light-orange color-block-mission"></div>
          <div className="color-block color-wide-purple color-block-mission"></div>
        </div>
      </div>
    </div>
  );
};  

export default Mission;
