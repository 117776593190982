import React from "react";
import logo from "../../assets/imgs/dark.png";
import "./Footer.css";
import {
  faLocationDot,
  faClock,
  faPhone,
  faEnvelope,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSquareInstagram,
  faFacebook,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";


const Footer = () => {
  return (
    <footer className="footer container py-4">
      <div className="row justify-content-between align-items-start">
        {/* Logo Section */}
        <div className="col-md-2 mb-3 d-flex flex-column align-items-center text-center">
          <img src={logo} alt="Logo" className="img-fluid logo-footer mb-2" />
        </div>

        {/* Navigation Links */}
        <div className="col-md-4 d-flex justify-content-around mb-3">
          <ul className="list-unstyled text-start">
            <li>
              <Link to="/" className="footer-link">
                Home
              </Link>
            </li>
            <li>
              <Link to="/about" className="footer-link">
                About
              </Link>
            </li>
          </ul>
          <ul className="list-unstyled text-start">
            <li>
              <Link to="/servicesPage" className="footer-link">
                Services
              </Link>
            </li>
            <li>
              <Link to="/events" className="footer-link">
                Events
              </Link>
            </li>
          </ul>
          <ul className="list-unstyled text-start">
            <li>
              <Link to="/team" className="footer-link">
                Team
              </Link>
            </li>
            <li>
              <Link to="/contactUs" className="footer-link">
                Contact
              </Link>
            </li>
          </ul>
        </div>

        {/* Contact Information */}
        <div className="col-md-3 mb-3 text-start">
          <ul className="list-unstyled">
            <li className="footer-icons-blocks">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="footer-icons me-2"
              />
              Houssam Eddine Hariri Street, Mosbah building, Saida, Lebanon
            </li>
            <li className="footer-icons-blocks">
              <FontAwesomeIcon icon={faClock} className="footer-icons me-2" />9
              AM - 9 PM
            </li>
          </ul>
        </div>

        <div className="col-md-3 mb-3 text-start">
          <ul className="list-unstyled">
            <li className="footer-icons-blocks">
              <FontAwesomeIcon icon={faPhone} className="footer-icons me-2" />
              +961 03 190 497 / +961 71 270 090
            </li>
            <li className="footer-icons-blocks">
              <FontAwesomeIcon
                icon={faEnvelope}
                className="footer-icons me-2"
              />
              info@ishbiliatheatre.com
            </li>
          </ul>
        </div>
      </div>

      {/* Social Media Section */}
      <div className="row mt-3 text-center">
        <div className="col-12">
          <span className="footer-social-title">Keep in touch!</span>
          <div className="footer-social-icons">
            <a href="https://www.instagram.com/ishbiliatheatre?igsh=MWJmd2VqYnl2MzdmYw==">
              <FontAwesomeIcon
                icon={faSquareInstagram}
                className="footer-icons social-icon me-2"
              />
            </a>
            <a href="https://www.facebook.com/IshbiliaTheatre">
              <FontAwesomeIcon
                icon={faFacebook}
                className="footer-icons social-icon me-2"
              />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
