import React, { useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import About from "./Pages/About/About";
import ContactUs from "./Components/ContactUs/ContactUs";
import MeetTeamComp from "./Components/MeetTeam/MeetTeamComp";
import ScrollToTop from "./Components/ScrollToTop";
import Login from "./Login";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import Events from "./Pages/Events/Events";
import Services from "./Pages/Services/Services";
import ServicesPage from "./Pages/Services/ServicesPage";
import Home from "./Pages/Home/Home";

function App() {
  const [authToken, setAuthToken] = useState<string | null>(
    localStorage.getItem("authToken")
  );

  const handleLogin = (token: string) => {
    setAuthToken(token);
    localStorage.setItem("authToken", token);
  };

  const handleLogout = () => {
    setAuthToken(null);
    localStorage.removeItem("authToken");
  };

  return (
    <BrowserRouter>
      <Navbar />
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="services" element={<Services />} />
        <Route path="servicesPage" element={<ServicesPage />} />
        <Route path="about" element={<About />} />
        <Route path="contactUs" element={<ContactUs />} />
        <Route path="events" element={<Events />} />
        <Route path="team" element={<MeetTeamComp />} />
        <Route path="/login" element={<Login onLogin={handleLogin} />} />
        <Route
          path="/admin"
          element={
            authToken ? (
              <AdminDashboard
               onLogout={handleLogout} 
               />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
      </Routes>
      <Footer />
      <div
        className="chat-button"
        onClick={() =>
          window.open(
            "https://wa.me/96103190497?text=Hello, I would like to inquire about your services.",
            "_blank"
          )
        }
      >
        Chat with Us
      </div>
    </BrowserRouter>
  );
}

export default App;
