import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import axios from "axios";
import "./AdminDashboard.css";
import dayjs from "dayjs";

interface Event {
  id?: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  image: string | File;
}

interface AdminDashboardProps {
  onLogout: () => void;
}

const AdminDashboard: React.FC<AdminDashboardProps> = ({ onLogout }) => {
  const [events, setEvents] = useState<Event[]>([]);
  const [newEvent, setNewEvent] = useState<Event>({
    title: "",
    description: "",
    start_date: "",
    end_date: "",
    image: "",
  });
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editEventId, setEditEventId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.get(
        "https://backend.ishbiliatheatre.com/api/events",
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      setEvents(response.data.data.events || []);
    } catch (err) {
      setError("Failed to fetch events. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setNewEvent({ ...newEvent, image: e.target.files[0] });
    }
  };

  const handleAddEvent = async (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
  
    // Format dates to DD/MM/YYYY
    const formattedStartDate = dayjs(newEvent.start_date).format("DD/MM/YYYY");
    const formattedEndDate = dayjs(newEvent.end_date).format("DD/MM/YYYY");
  
    formData.append("title", newEvent.title);
    formData.append("description", newEvent.description);
    formData.append("start_date", formattedStartDate);
    formData.append("end_date", formattedEndDate);
  
    if (newEvent.image instanceof File) {
      formData.append("image", newEvent.image);
    } else {
      console.error("Image is not a File instance.");
    }
  
    try {
      const response = await axios.post(
        "https://backend.ishbiliatheatre.com/api/events/store",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Event added successfully:", response.data);
      fetchEvents();
      setNewEvent({
        title: "",
        description: "",
        start_date: "",
        end_date: "",
        image: "",
      });
    } catch (err: any) {
      console.error("Failed to add event:", err.response?.data || err.message);
      setError("Failed to add event. Please check your input.");
    }
  };
  
  

  const handleDeleteEvent = async (id: number) => {
    try {
      await axios.get(
        `https://backend.ishbiliatheatre.com/api/events/delete/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      fetchEvents();
    } catch (err) {
      setError("Failed to delete event. Please try again.");
    }
  };

  const handleEditEvent = (event: Event) => {
    setNewEvent(event);
    setIsEditing(true);
    setEditEventId(event.id || null);
  };

  const handleUpdateEvent = async (e: FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
  
    // Format dates to DD/MM/YYYY
    const formattedStartDate = dayjs(newEvent.start_date).format("DD/MM/YYYY");
    const formattedEndDate = dayjs(newEvent.end_date).format("DD/MM/YYYY");
  
    formData.append("title", newEvent.title);
    formData.append("description", newEvent.description);
    formData.append("start_date", formattedStartDate);
    formData.append("end_date", formattedEndDate);
  
    if (newEvent.image instanceof File) {
      formData.append("image", newEvent.image);
    }
  
    try {
      await axios.put(
        `https://backend.ishbiliatheatre.com/api/events/${editEventId}`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
      fetchEvents();
      setNewEvent({
        title: "",
        description: "",
        start_date: "",
        end_date: "",
        image: "",
      });
      setIsEditing(false);
      setEditEventId(null);
    } catch (err) {
      setError("Failed to update event. Please try again.");
    }
  };

  const isFormValid = () => {
    return (
      newEvent.title &&
      newEvent.description &&
      newEvent.start_date &&
      newEvent.end_date &&
      (!isEditing || newEvent.image instanceof File)
    );
  };

  return (<div className="admin-dashboard-container-dashboard">
    <div className="dashboard-header-dashboard">
      <h1>Admin Dashboard - Manage Events</h1>
      <button onClick={onLogout} className="btn-logout-dashboard">
        Logout
      </button>
    </div>
  
    <form
      onSubmit={isEditing ? handleUpdateEvent : handleAddEvent}
      className="event-form-dashboard"
    >
      <input
        type="text"
        name="title"
        placeholder="Event Title"
        value={newEvent.title}
        onChange={handleInputChange}
        required
      />
      <input
        type="text"
        name="description"
        placeholder="Event Description"
        value={newEvent.description}
        onChange={handleInputChange}
        required
      />
      <input
        type="date"
        name="start_date"
        placeholder="Start Date"
        value={newEvent.start_date}
        onChange={handleInputChange}
        required
      />
      <input
        type="date"
        name="end_date"
        placeholder="End Date"
        value={newEvent.end_date}
        onChange={handleInputChange}
        required
      />
      <input
        type="file"
        name="image"
        onChange={handleFileChange}
        required={!isEditing}
      />
      <button type="submit" className="btn-primary-dashboard" disabled={!isFormValid()}>
        {isEditing ? "Update Event" : "Add Event"}
      </button>
    </form>
  
    {error && <p className="error-message-dashboard">{error}</p>}
  
    <h2>Existing Events</h2>
    {isLoading ? (
      <p>Loading...</p>
    ) : (
      <div className="events-container-dashboard">
        {events.length > 0 ? (
          events.map((event) => (
            <div className="event-card-dashboard" key={event.id}>
              <img
                src={event.image as string}
                alt={event.title}
                className="event-img-dashboard"
              />
              <h3>{event.title}</h3>
              <p>{event.description}</p>
              <p>
                {event.start_date} - {event.end_date}
              </p>
              <div className="event-actions-dashboard">
                <button
                  onClick={() => handleEditEvent(event)}
                  className="btn-edit-dashboard"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDeleteEvent(event.id as number)}
                  className="btn-delete-dashboard"
                >
                  Delete
                </button>
              </div>
            </div>
          ))
        ) : (
          <p>No events available</p>
        )}
      </div>
    )}
  </div>
  );
};

export default AdminDashboard;
  