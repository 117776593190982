import React, { useState } from "react";
import "./Tabs.css";

// Define the tab content type
interface TabContent {
  title: string;
  content: JSX.Element;
}

const Tabs: React.FC<{ tabs: TabContent[] }> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  // Array of background colors for tabs
  const tabColors = ["#ff766a", "#fdad7a","#bceae8",  "#c35280", "#ffe98c" ,"#c387ba"];

  return (
    <div className="container">
      {/* Tabs Header */}
      <div className="tabs-header">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`tab-button ${activeTab === index ? "active" : ""}`}
            style={{
              backgroundColor: activeTab === index ? tabColors[index] : "#ffffff", // Background color only if active
              color: activeTab === index ? "white" : "black", // Active tab text color
            }}
            onClick={() => setActiveTab(index)}
          >
            {tab.title}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      {tabs[activeTab] && tabs[activeTab].content ? (
        <div className="tab-content">{tabs[activeTab].content}</div>
      ) : (
        <div className="tab-content">Content not available</div>
      )}
    </div>
  );
};

export default Tabs;
