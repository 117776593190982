import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/imgs/dark.png";
import "./Navbar.css";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <>
      <nav className="navbar-container">
        <div className="container navbar-inner-container">
          <Link to="/" className="navbar-brand">
            <img
              src={logo}
              alt="Logo"
              className="w-16"
              style={{ width: "50px" }}
            />
          </Link>
          <button className="navbar-toggler" onClick={toggleMenu}>
            <span className="navbar-toggler-icon">&#9776;</span>
          </button>
          <div className={`navbar-collapse ${isMenuOpen ? "show" : ""}`}>
            <ul className="navbar-nav-items">
              <li className="nav-item">
                <Link to="/" className="nav-link" onClick={closeMenu}>
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/about" className="nav-link" onClick={closeMenu}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/servicesPage" className="nav-link" onClick={closeMenu}>
                  Services
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/events" className="nav-link" onClick={closeMenu}>
                  Events
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/team" className="nav-link" onClick={closeMenu}>
                  Team
                </Link>
              </li>
              <li className="nav-item">
                <Link to="/contactUs" className="nav-link" onClick={closeMenu}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
