import React, { useEffect, useState } from "react";
import "./Events.css";

interface Event {
  id: number;
  title: string;
  description: string;
  start_date: string;
  end_date: string;
  image: string;
}

const Events: React.FC = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  const colors = ["#FF766A", "#C35280", "#FFE98C", "#FDAD7A", "#BCEAE8", "#C387BA"];

  useEffect(() => {
    fetchEvents();
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const fetchEvents = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await fetch("https://backend.ishbiliatheatre.com/api/events");
      const data = await response.json();
      if (data?.data?.events) {
        setEvents(data.data.events);
      } else {
        throw new Error("Invalid response structure");
      }
    } catch (err: any) {
      setError("Failed to fetch events. Please try again later.");
      console.error("Error fetching events:", err.message || err);
    } finally {
      setIsLoading(false);
    }
  };

  // Utility function to capitalize the first letter of every word in a string
  const capitalizeTitle = (title: string): string => {
    return title
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  // Utility function to format the date
  const formatDate = (date: string): string => {
    const options: Intl.DateTimeFormatOptions = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(date).toLocaleDateString("en-US", options);
  };

  return (
    <div className="event-page-container">
      <div className="events-page-title">Upcoming Events</div>
      {isLoading ? (
        <p>Loading events...</p>
      ) : error ? (
        <p className="error-message">{error}</p>
      ) : events.length > 0 ? (
        <div className="all-events-container container">
          {events.map((event, index) => (
            <div className="event-card" key={event.id}>
              <div className="event-container">
                <img
                  src={event.image || "placeholder-image-url.jpg"}
                  className="event-img"
                  alt={event.title}
                />
                <div className="event-container-title">
                  {capitalizeTitle(event.title)}
                </div>
                <div className="event-container-date">
                  {formatDate(event.start_date)}
                </div>
              </div>
              <div
                className="event-content"
                style={{
                  backgroundColor: colors[index % colors.length],
                }}
              >
                <div className="event-content-title">
                  {capitalizeTitle(event.title)}
                </div>
                <div className="event-content-date">
                  {formatDate(event.start_date)}
                </div>
                <div className="event-content-disc">{event.description}</div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No events available at the moment.</p>
      )}
    </div>
  );
};

export default Events;
