import theaterBackground from "../src/assets/imgs/rectangle.jpg";
import nahlaImage from "../src/assets/team/nahla png.png";
import hibaImage from "../src/assets/team/hiba png.png";
import ghidaImage from "../src/assets/team/ghida png.png";
import haniImage from "../src/assets/team/hani png.png";
export const meetTeam = [
  {
    name: "Nahla Zibawi",
    role: "Co-founder & Managing Director",
    title: "The Correct",
    content: `Nahla EL-Zibawi is the Co-founder and Managing Director of Ishbilia Theatre & Art Hub in Saida. Coming from a business background, EL- Zibawi brings to the Cultural and Creative sector a set of ideas and skills from project design and business modeling to partnership building. Nahla is currently responsible for the strategic direction of the space along with managing its day-to-day operations.

Before reviving Ishbilia, Nahla was a specialist in the development sector where she led several projects related to capacity building, culture, and local development.

Nahla holds a BA in Business Administration, a major in Management, and a Master in Market Research and Consumer Behavior from IE Business School, Madrid, Spain.

In her free time, Nahla likes to do Aerial yoga and Pilates and disconnects from stressful times through painting and coloring.

She believes that no matter what circumstances you are living in or what difficulties you are facing, do not wait to see where the change will take you, be the change instead. `,
    image: nahlaImage,
  },
  {
    name: "Hiba Zibaoui",
    role: "Co-founder & Artistic Director",
    title: "The Over Thinker",
    content: `Hiba Zibaoui is a cultural manager and practitioner. She founded Ishbilia for Arts Association in 2018. In this respect, she serves as an Artistic Director of Ishbilia Theatre and Art Hub in Sidon, South Lebanon.

She is responsible for programing, planning, and organizing the cultural events at the space. The program offering featured archived and modern content that speaks to various interests and age groups. The programs include film screenings, performing art festivals, training, and workshops. 

She participated in many programs, talks, events, and workshops related to culture and arts like CICAE Art Training 2024 in Berlin, “Tandem 360”, Beirut Locarno Film Academy, and Sharjah Film Platform in 2021. 

Hiba holds a bachelor’s degree in Communication Arts from the Lebanese American University.  Her experiences involved media, theatre, drama therapy, and research. 

She sees Culture as a main pillar for communities and at the same time as a cross beam connecting all the other pillars. 

Loves ice cream, believes in fairytales, and adores Egyptian culture and cinema.
 `,
    image: hibaImage,
  },
  {
    name: "Ghida Yaman",
    role: "Cultural Advisor ",
    title: "The Wise Voice",
    content: `Ghida holds a BE in Computer and Communications Engineering and an MBA from the American University of Beirut. She worked for a couple of years with computers, then moved to administration and translation. She wrote articles for websites and magazines and published a few books.

As a child in the early 80s, Ghida witnessed the birth of Ishbilia as a movie theater in Saida, then years later she watched it reopening its doors as a multi-purpose meeting space for arts and culture. She is lucky to be a part of the team, brainstorming ideas and following up on projects.

Ghida doesn’t like sports nor physical exercise but loves planting vegetables within a small local community in Saida. She follows Bilal Fadl on YouTube, and tries as hard as she can to implement his advice: “Stay away from what vexes you and stick instead to what doesn’t».`,
    image: ghidaImage,
  },
  {
    name: "Hani Boubou",
    role: "Technical Manager",
    title: "The One & Only",
    content: `Hani Boubou has been working as a theatre technical manager for more than 20 years and is known for being the technical Guru for events in Saida. His career started because of his love for music. His expertise evolves around sound and lights installments, setups, and technical operations. Boubou launched his own company “Calyspo” for sound and light equipment selling in 2009. He is the technical manager at Ishbilia who we can’t run any event without. Hani’s phone is always busy, during his rare free time, he likes to roam around his car and listen to music. His favorite drink at Ishbilia is Irish Cream Ice Coffee.`,
    image: haniImage,
  },
];

const roomsData = [
  {
    name: "Cinema & Theatre",
    threeWords: "Curiosity, Creativity, Compassion",
    details: [
      "Stage Dimensions: 6.9 times 14.4",
      "Backstage with toilets",
      "Power Supply Type: Generator",
      "Audio: 6 speakers (15 inch), 3 left and 3 right (connected to the amps), 2 wireless microphones",
      "Air Conditioning",
      "Screen: (7 * 12)",
      "Cinema Projector: Christie",
      "Lighting: 8 RGBW LED PAR, 5 pcs 1000 watt (above the audience facing the stage), 4 sides facing the stage (2 left, 2 right)",
      "Dimmer: 6 channel + 4 channel",
      "Console: 24 channel",
      "Capacity: 447 seats",
    ],
    image: theaterBackground,
    idealFor:
      "Film Screenings, Plays, Musical Performances, Workshops, Seminars, Graduations",
  },
  {
    name: "Multi-Disciplinary Room",
    threeWords: "Imagine, Inspire, Innovate",
    details: [
      "Customizable space for teams'\u00A0meeting, training & events",
      "Audiovisual Equipment: TV + HDMI + Speakers + Two Microphones",
      "White Board with flip chart",
      "Fast & free Internet",
      "Air Conditioning",
      "Catering services",
      "Capacity: Welcomes up to 50 people",
    ],
    image: theaterBackground,
    idealFor: "Workshops, Talks, Meetings, Seminars, Exhibitions",
  },
  {
    name: "Meeting Room",
    threeWords: "Connectedness, Construction, Clarity",
    details: [
      "Fixed meeting table",
      "Audiovisual Equipment: TV + HDMI",
      "White Board with flip chart",
      "Fast & free Internet",
      "Air Conditioning",
      "Catering services",
      "Capacity: Welcomes up to 15 people",
    ],
    image: theaterBackground,
    idealFor:
      "Interviews, Freelancers, Tutors, Zoom calls, Group work/meetings, Small training",
  },
  {
    name: "Coworking Space",
    threeWords: "Engage, Elevate, Excel",
    details: [
      "Free & unlimited access to the coworking area during business hours",
      "Calm & Convenient Workspace",
      "Fast & free Internet",
      "Air Conditioning",
    ],
    image: theaterBackground,
    idealFor: "Studying, Working, Reading a Book",
  },
  {
    name: "Your Home Away From Home - Coffee Shop",
    threeWords: "",
    details: [
      "Variety of Hot & Cold Drinks, Yummy Desserts & a selection of fresh sandwiches",
      "Homey and Cosy",
      "Flexible Workspace",
      "Fast & free Internet",
      "Air Conditioning",
    ],
    image: theaterBackground,
    idealFor:
      "Chilling, Studying, Working on laptops, Catching up with friends, Sipping coffee, Have some me time, Poetry Nights, playing board games",
  },
];

export default roomsData;
